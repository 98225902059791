body {
    background-color:#eeeeee;
    background-image: url('https://lmpixels.com/demo/procard/images/sp_main_bg.png');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    font-size: 15px;
}
.container {
    max-width: 1032px;
}
/* .navbar-brand span,
.title h3 span {
    color: #2eca7f;
}
a {
    color: #2eca7f;
}
a:hover {
    color: #ff9800;
} */
.navbar-brand {
    margin: 38px 0 0;
    color: #49515d !important;
    font-size: 30px;
    font-weight: 500;
}
.navbar-brand span{
    color: #2eca7f;
}
.nav-link {
    margin: 38px 0 0;
    color: #91979f !important;
    font-size: 18px;
    font-weight: 400;
}
.page-content {
    background-color:#fff;
    padding: 0;
    overflow: hidden;
    border-radius: 15px;
}
.page-header {
    position: relative;
    background-color: #2eca7f;
    padding: 50px;
    margin-bottom: 25px;
    background-image: url('https://lmpixels.com/demo/procard/images/sp_main_bg.png');
    background-size: cover;
    background-position: center center;
    background-attachment: scroll;
    background-repeat: no-repeat;
}
.photo {
    max-width: 280px;
    margin-bottom: -78px;
    border: 3px solid #fff;
    background-color: #fff;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .1);
    transition: all .3s ease-in-out;
}
.photo:hover {
    transform: translateY(-9px);
    box-shadow: 0 18px 24px rgba(0, 0, 0, .15);
}
.photo img { 
    max-width: 100%;
    vertical-align: bottom;
    height: auto;
    border: 0;
}
.title-block {
    text-align: center;
}
.title-block h1{
    font-size: 54px;
    font: 700;
    color: #fff;
    margin: 30px 0 0 0;
}
.title-block p{
    font-size: 18px;
    color: #fff;
    margin: 0;
   
}
/* .navbar {
    background-color: transparent !important;
} */
.page-about-content {
    padding: 20px 50px 50px 50px;
    background-color: #fff;
}
.about-me {
    text-align: left;
}
footer .copyrights {
    display: block;
    margin: 12px 0;
    text-align: center;
    font-size: 12px;
    color: #9e9e9e;
}
.download-resume {
    margin-top: 25px;
}
.download-resume a {
    padding: 10px 27px;
    background-color: #fff;
    color: #000;
    border: none;
    text-transform: uppercase;
   box-shadow: 0 1px 1px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .3);
}
.info-list{
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
}
.info-list li {
    margin-top: 10px;
    text-align: left;
}
.info-list li span {
    min-width: 120px;
    display: inline-block;
}
.value{
    color: #9e9e9e;
}
.download-resume a:hover {
    background-color: #fff;
    color:#000;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1), 0 4px 8px rgba(0, 0, 0, .3);
}
